import React from 'react'

import { Helmet } from 'react-helmet'

import BlogDir from '../components/blog-dir'
import './blog-empty.css'

const BlogEmpty = (props) => {
  return (
    <div className="blog-empty-container">
      <Helmet>
        <title>blog-empty - dylans garden</title>
        <meta property="og:title" content="blog-empty - dylans garden" />
      </Helmet>
      <div className="blog-empty-container1">
        <BlogDir rootClassName="blog-dir-root-class-name2"></BlogDir>
        <div className="blog-empty-container2"></div>
      </div>
    </div>
  )
}

export default BlogEmpty
