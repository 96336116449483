import React from 'react'

import { Helmet } from 'react-helmet'

import BlogDir from '../components/blog-dir'
import './blog-home.css'

const BlogHome = (props) => {
  return (
    <div className="blog-home-container">
      <Helmet>
        <title>blog-home - dylans garden</title>
        <meta property="og:title" content="blog-home - dylans garden" />
      </Helmet>
      <div className="blog-home-container1">
        <BlogDir rootClassName="blog-dir-root-class-name"></BlogDir>
        <div className="blog-home-container2">
          <h1 className="blog-home-text">dylans blog title</h1>
        </div>
      </div>
    </div>
  )
}

export default BlogHome
