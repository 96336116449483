import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>dylans garden</title>
        <meta property="og:title" content="dylans garden" />
      </Helmet>
      <ul className="home-ul list">
        <li className="home-li list-item">
          <span className="home-text">dylan turner</span>
        </li>
        <li className="home-li1 list-item">
          <span className="home-text01">x : </span>
          <a
            href="https://x.com/minorponky"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link"
          >
            <span>minorponky</span>
            <br></br>
          </a>
        </li>
        <li className="home-li2 list-item">
          <span className="home-text04">
            <span>linkedin : </span>
            <br></br>
          </span>
          <a
            href="https://www.linkedin.com/in/dylanturner09/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1"
          >
            <span>dylanturner09</span>
            <br></br>
            <br></br>
          </a>
        </li>
        <li className="home-li3 list-item">
          <div className="home-container1">
            <a
              href="https://portfolio.dylans.garden"
              className="home-link2 button"
            >
              <span className="home-text10">
                <span>portfolio</span>
                <br></br>
              </span>
            </a>
            <a href="https://blog.dylans.garden" className="home-link3 button">
              <span className="home-text13">
                <span>blog</span>
                <br></br>
              </span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Home
