import React from 'react'

import { Helmet } from 'react-helmet'

import './portfolio.css'

const Portfolio = (props) => {
  return (
    <div className="portfolio-container">
      <Helmet>
        <title>portfolio - dylans garden</title>
        <meta property="og:title" content="portfolio - dylans garden" />
      </Helmet>
    </div>
  )
}

export default Portfolio
