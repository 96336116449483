import React from 'react'

import PropTypes from 'prop-types'

import './blog-dir.css'

const BlogDir = (props) => {
  return (
    <div className={`blog-dir-container ${props.rootClassName} `}>
      <div className="blog-dir-container1">
        <button type="button" className="blog-dir-button button">
          home
        </button>
        <button type="button" className="blog-dir-button1 button">
          <span className="blog-dir-text">
            <span className="">blog</span>
            <br className=""></br>
          </span>
        </button>
      </div>
      <ul className="blog-dir-ul list">
        <li className="blog-dir-li list-item">
          <span className="blog-dir-text03">
            <span className="">p</span>
            <br className=""></br>
          </span>
        </li>
        <li className="blog-dir-li1 list-item">
          <span className="blog-dir-text06">
            <span className="">o</span>
            <br className=""></br>
          </span>
        </li>
        <li className="blog-dir-li2 list-item">
          <span className="blog-dir-text09">s</span>
        </li>
        <li className="blog-dir-li3 list-item">
          <span className="blog-dir-text10">
            <span className="">t</span>
            <br className=""></br>
          </span>
        </li>
        <li className="blog-dir-li4 list-item">
          <span className="blog-dir-text13">
            <span className="">s</span>
            <br className=""></br>
          </span>
        </li>
      </ul>
      <ul className="blog-dir-ul1 list">
        <li className="blog-dir-post-2 list-item">
          <ul className="list">
            <li className="list-item">
              <span className="blog-dir-text16">
                <span className="">post 2 name</span>
                <br className=""></br>
              </span>
            </li>
            <li className="list-item">
              <span className="blog-dir-text19">
                <span className="">post 2 date</span>
                <br className=""></br>
              </span>
            </li>
          </ul>
        </li>
        <li className="blog-dir-post-1-example list-item">
          <ul className="list">
            <li className="list-item">
              <span className="blog-dir-text22">
                <span className="">post 1 name</span>
                <br className=""></br>
              </span>
            </li>
            <li className="list-item">
              <span className="blog-dir-text25">
                <span className="">post 1 date</span>
                <br className=""></br>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

BlogDir.defaultProps = {
  rootClassName: '',
}

BlogDir.propTypes = {
  rootClassName: PropTypes.string,
}

export default BlogDir
